<template>
  <circle cx="36" cy="36.5" r="36" fill="#F1F4F7" />
  <path
    d="M21 20.5C21 18.2909 22.7909 16.5 25 16.5H51C53.2091 16.5 55 18.2909 55 20.5V56.5C55 58.7091 53.2091 60.5 51 60.5H25C22.7909 60.5 21 58.7091 21 56.5V20.5Z"
    fill="#DAE5F5"
  />
  <path
    d="M32.5 12.5C30.567 12.5 29 14.067 29 16C29 17.933 30.567 19.5 32.5 19.5H41.5C43.433 19.5 45 17.933 45 16C45 14.067 43.433 12.5 41.5 12.5H32.5Z"
    fill="#DAE5F5"
    stroke="#F1F4F7"
    stroke-width="2"
  />
  <path
    d="M19 18.5C19 16.2909 20.7909 14.5 23 14.5H49C51.2091 14.5 53 16.2909 53 18.5V54.5C53 56.7091 51.2091 58.5 49 58.5H23C20.7909 58.5 19 56.7091 19 54.5V18.5Z"
    fill="white"
  />
  <path
    d="M35.5 30.5H28C26.8954 30.5 26 31.3954 26 32.5V35.5C26 36.6046 26.8954 37.5 28 37.5H31"
    stroke="#A3B1D1"
    stroke-width="2"
    stroke-linecap="round"
  />
  <g clip-path="url(#clip0_6875_29380)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.8244 49.0479L53.1051 50.2325C52.865 50.628 52.4849 50.9064 52.0352 51.0164C51.5855 51.1263 51.1199 51.0546 50.7245 50.8144L49.2734 49.9334L51.7914 45.7863L53.2425 46.6673C54.0334 47.1475 54.3051 48.2562 53.8244 49.0479ZM39.9654 38.605L50.608 45.0662L48.09 49.2135L37.4478 42.7514C37.3943 42.7189 37.3502 42.6726 37.3209 42.6169L35.4429 39.044C35.4296 39.0188 35.4195 38.992 35.4129 38.965C35.3914 38.8769 35.4053 38.7822 35.4531 38.7035C35.5162 38.5994 35.6298 38.536 35.7513 38.5364L39.7875 38.5543C39.8497 38.5548 39.9118 38.5725 39.9654 38.605Z"
      fill="#8497C2"
    />
  </g>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M34.083 10.5895C33.5 10.5409 33 11.001 33 11.586V12.4992H31C30.4477 12.4992 30 12.9469 30 13.4992V17.4992C30 18.0515 30.4477 18.4992 31 18.4992H43C43.5523 18.4992 44 18.0515 44 17.4992V13.4992C44 12.9469 43.5523 12.4992 43 12.4992H39.6667L39.2405 11.5404C39.0922 11.2067 38.7737 10.9804 38.4098 10.95L34.083 10.5895Z"
    fill="#DAE5F5"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M33 9.5C32.4477 9.5 32 9.94772 32 10.5V11.5H30C29.4477 11.5 29 11.9477 29 12.5V16.5C29 17.0523 29.4477 17.5 30 17.5H42C42.5523 17.5 43 17.0523 43 16.5V12.5C43 11.9477 42.5523 11.5 42 11.5H40V10.5C40 9.94772 39.5523 9.5 39 9.5H33Z"
    fill="#A3B1D1"
  />
  <circle cx="36" cy="11.5" r="1" fill="#F1F4F7" />
  <defs>
    <clipPath id="clip0_6875_29380">
      <rect
        width="16"
        height="16"
        fill="white"
        transform="translate(50.8711 34.8301) rotate(76.2644)"
      />
    </clipPath>
  </defs>
</template>
